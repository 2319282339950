import layout from '@/layout'

export default {
  path: '/job',
  component: layout,
  redirect: '/job/list',
  name: 'jobRecommend',
  meta: { title: 'job', icon: 'article' },
  children: [
    {
      path: '/job/list',
      component: () =>
        import(
          /* webpackChunkName: "job-recommend" */ '@/views/job/index'
        ),
      meta: {
        title: 'jobRecommend',
        icon: 'article'
      }
    }
  ]
}
