import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import layout from '@/layout'
// import UserManageRouter from './modules/UserManage'
// import RoleListRouter from './modules/RoleList'
// import PermissionListRouter from './modules/PermissionList'
// import ArticleCreaterRouter from './modules/ArticleCreate'
// import ArticleRouter from './modules/Article'
import JobRouter from './modules/Job'
import store from '@/store'

/**
 * 私有路由表
 */
// UserManageRouter,
// RoleListRouter,
// PermissionListRouter,
// ArticleCreaterRouter
// ArticleRouter
export const privateRoutes = [
  JobRouter
]

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index')
  },
  {
    path: '/',
    component: layout,
    redirect: '/job/list',
    name: 'jobRecommend',
    meta: { title: 'job', icon: 'article' },
    children: [
      {
        path: '/job/list',
        component: () =>
          import(
            /* webpackChunkName: "job-recommend" */ '@/views/job/index'
          ),
        meta: {
          title: 'jobRecommend',
          icon: 'article'
        }
      },
      {
        path: '/404',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/401')
      }
    ]
  }
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    store.getters.userInfo &&
    store.getters.userInfo.permission &&
    store.getters.userInfo.permission.menus
  ) {
    const menus = store.getters.userInfo.permission.menus
    menus.forEach(menu => {
      router.removeRoute(menu)
    })
  }
}

const router = createRouter({
  history:
    process.env.NODE_ENV === 'production'
      ? createWebHistory()
      : createWebHashHistory(),
  routes: publicRoutes
})

export default router
